.products-top-bar{
    max-width: 1980px;
    position: fixed;
    z-index: 1000;
    background-color: #ffffff;
    transition: top 0.1s;
}
.open-menu-btn{
    width: max-content;
    height: 50px;
    display: inline-flex;
    align-items: center;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 13px;
}
.open-menu-btn:hover{
    border: 2px solid rgba(0, 0, 0, 0.95) !important;
}
.open-menu-btn svg{
    display: block;
    margin: 0 auto;
    text-align: center;
}
.search-container{
    height: 50px;
}
.search-container input{
    height: 50px;
}
.search-container .input-group-text{
    background-color: #ffffff !important;
}
.cart-container{
    height: 50px;
    display: flex;
    align-items: center;
}
@media screen and (min-width: 100px) and (max-width: 1167px) {
    .cart-container{
        margin-top: 20px;
    }
}
.shopping-basket{
    position: fixed;
    display: block;
    margin: 0 auto;
    text-align: center;
    border: 3px #212529 solid;
    border-radius: 45px;
    padding: 6px;
}
.shopping-basket span{
    width: 50px;
    top: 1px;
    position: absolute;
    color: #ff0606;
    font-size: 12px;
    padding: 6px;
}
.shopping-basket img path{
    stroke: #000000;
    stroke-width: 30px;
}
@media screen and (min-width: 100px) and (max-width: 600px){
    .badge{
        max-width: 270px;
        overflow: auto;
    }
}
.shopping-basket .badge-dark{
    background: linear-gradient(250deg, #212529 0%, #212529 85%, rgba(0, 0, 0, 0) 80%)
}
.shopping-basket-effect{
    animation: basket-effect 0.5s;
}
@keyframes basket-effect {
    0% {
        transform: scale(1);
    }
    50%{
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
.user-container{
    height: 50px;
    display: inline-block;
    overflow: hidden;
}
.user-container img{
    display: flex;
    margin: auto;
}
.user-data{
    max-width: 150px;
    display: block;
    margin: 0 auto;
    text-align: center;
    color: #1b3307;
    text-shadow: 0.5px 0.5px 2px #1b33077a;
    font-size: 12px;
}
.popover-container{
    position: absolute;
    margin: auto;
    padding-top: 10px;
    right: 0;
    left: 0;
    top: 67px;
    color: white;
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    box-shadow: 2px 2px 10px 0px #000000c7;
    animation: popover-fade-in 10s;
}
.popover-arrow{
    position: absolute;
    top: -28px;
    left: 0;
    right: 0;
    color: black;
}
@keyframes popover-fade-in {
    0% {
        opacity: 0;
    }
    25%{
        opacity: 1;
    }
    90%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
.sort-arrow{
    display: inline-flex;
    top: -7px;
    position: relative;
}
.menu-sort-container{
    min-width: 280px;
}
.d-grid{
    display: grid;
}
.search-container input{
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
}
.search-container span{
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
}
.min-400{
    min-width: 400px;
}
