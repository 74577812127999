.contact{
    max-width: 1500px;
}
.map{
    width: 100%;
    height: 250px;
    border: 0 !important;
}
.contact-info-section{
    max-width: 500px;
    height: 100%;
    min-height: 200px;
}
.contact-info-section-bottom{
    position: absolute;
    left: 5px;
    bottom: 6px;
    margin: auto;
}
.about-us-container{
    height: 400px;
    background-image: url("../../media/about-us-img.jpg");
    background-repeat: no-repeat;
    background-position: center;
}
.about-us-text-wrapper{
    width: 500px;
    margin: auto;
}
.about-us-text{
    color: #fefffe !important;
    font-size: 30px !important;
    text-align: center;
    filter: drop-shadow(3px 3px 3px rgb(37, 0, 255));
    text-shadow: 0 0 2px rgb(36, 0, 251);
}
@media screen and (min-width: 300px) and (max-width: 550px) {
    .about-us-text-wrapper{
        width: 300px;
    }
}
