.user-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    border: 2px solid #0003;
    border-radius: 10px;
    background-color: #ffffff;
}
