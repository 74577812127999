
.dropdown-products-menu{
    position: fixed;
    width: 100%;
    max-width: 1980px;
    top: 2000px;
    height: calc(100% - 150px);
    overflow-y: scroll;
    background-color: #ffffff;
    z-index: 1001;
}
.dropdown-products-menu .col{
    display: inline-table;
    margin: auto;
    flex-grow: initial;
}
.dropdown-products-menu .product-center div{
    justify-items: center;
    display: grid;
}
.category-border{
    border: 1px rgba(0, 0, 0, 0) solid;
    box-shadow: 0 0 12px 1px #0000001a;
    border-radius: 12px;
}
.category-selected-border{
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px #00000040 solid;
    box-shadow: 1px 1px 7px #000000E5;
}
.category-text{
    height: 72px;
}
.subcategory-section .badge-secondary{
    background-color: #5e5e5e !important;
}
.products{
    position: relative;
    min-height: 450px;
    margin-top: 130px;
}
.products .row{
    display: flow;
}
.products .col{
    max-width: 248px;
    display: inline-table;
    margin: auto;
}
.product-container{
    display: inline-block;
    border: 1px #00000040 solid;
    border-radius: 15px;
    overflow: hidden;
}
.product-container-wrapper{
    margin: 10px;
}
.product-img-container{
    width: 200px;
    height: 200px;
    overflow: hidden;
    padding: 10px;
}
.product-img-container-hover-zoom:hover{
    transition: transform .5s;
    transform: scale(1.1);
    filter: brightness(110%);
}
.product-bottom-container{
    width: 170px;
    height: 125px;
    font-size: 12px;
    margin: auto;
}
.product-bottom-container p{
    height: 36px;
    margin: 2px;
    overflow: hidden;
}
.product-name{
    height: 38px !important;
    font-size: 12px;
}
.product-bottom-container button{
    margin: 5px;
}
.number-button-wrapper{
    width: 110px;
    display: inline-block;
    text-align: center;
    border: 2px #0000004a dotted;
    border-radius: 20px;
}
.number-button-wrapper input{
    width: 30px;
    height: 25px;
    border: none;
    -moz-appearance: textfield;
    outline: none;
    margin-top: 2px;
    text-align: center;
}
.number-button-wrapper .decrement{
    width: 30px;
    background: transparent;
    margin: 0;
    border: 0;
    right: 5px;
    position: relative;
}
.number-button-wrapper .increment{
    width: 30px;
    background: transparent;
    margin: 0;
    border: 0;
    left: 3px;
    position: relative;
}

.number-button-wrapper span{
    width: 30px;
    display: inline-block;
    font-size: 12px;
}
.add-to-cart-button{
    border-radius: 20px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 30px;
    margin: 0 !important;
    background-color: transparent;
}
.add-to-cart-button:hover{
    width: 110px;
    transition: width 0.1s;
}
.discounted-price{
    position: relative;
    display: inline-block;
    top: 2px;
    color: #007515;
}

.old-price{
    position: relative;
    display: inline-block;
    top: 12px;
    transform: scale(0.9);
    font-size: 12px;
}
.old-price::before, .cross::after {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
}
.old-price::before {
    border-bottom: 2px solid #e30000;
    transform: skewY(-9deg);
}
.pagination-section{
    margin-bottom: 15px;
}
