.MuiModal-root{
    overflow-y: scroll;
}
.checkout-modal{
    position: absolute;
    width: 90%;
    max-width: 1000px;
    right: 0;
    left: 0;
    top: 20px;
    margin: auto;
    background-color: rgb(255, 255, 255);
}
.checkout-modal-body{
    /*width: 700px;*/
    margin: auto;
    padding: 5px;
    border: 2px rgba(0, 0, 0, 0.3) outset;
    border-radius: 5px;
}
.checkout-form .col-6{
    min-width: 320px;
}
.min-w-320{
    width: 100%;
    min-width: 320px;
}
.max-w-100{
    max-width: 100px;
}
.max-w-50{
    max-width: 50px;
}
.payment-method-container{
    border: 2px rgba(0, 0, 0, 0) dashed;
    border-radius: 5px;
    opacity: 0.7;
    padding: 5px;
}
.payment-method-container-selected{
    border-color: rgba(0, 0, 0, 0.5);
    opacity: 0.9;
}
@media (min-width: 500px) and (max-width: 750px) {
    .checkout-modal-body{
        width: 480px;
    }
    .checkout-form .col-6{
        min-width: 100%;
    }
}
@media (max-width: 500px) {
    .checkout-modal-body{
        width: 300px;
    }
    .checkout-form .col-6{
        min-width: 100%;
    }
}
.remove-btn:hover{
    color: rgba(0, 0, 0, 0.8);
}

.react-datetime-picker__inputGroup{
    width: 250px;
    height: 36px;
}
.react-datetime-picker__wrapper{
    border: thin solid #ced4da !important;
    border-radius: .25rem;
}
.react-datetime-picker__inputGroup__day{
    width: 28px !important;
    text-align: center;
}
.react-datetime-picker__inputGroup__month{
    width: 28px !important;
    text-align: center;
}
.react-datetime-picker__inputGroup__year{
    width: 75px !important;
}
.react-datetime-picker__inputGroup__hour{
    width: 28px !important;
}
.react-datetime-picker__inputGroup__minute{
    width: 28px !important;
}

/*.checkbox-asap{*/
/*    margin: auto;*/
/*    display: block;*/
/*    height: 30px;*/
/*    width: 38px;*/
/*    background-color: #eee;*/
/*}*/
/*.checkbox-asap input:checked {*/
/*    background-color: #2196F3;*/
/*}*/
