@font-face {
  font-family: Armenian;
  src: url('./static/NotoSansArmenian-Regular.woff2');
}
@font-face {
  font-family: Russian;
  src: url('./static/Roboto-Bold.ttf');
}
@font-face {
  font-family: English;
  src: url('./static/Roboto-Bold.ttf');
}
:lang(hy){
  font-family: Armenian;
  font-size-adjust: 0.6;
  font-weight: bolder;
}
:lang(ru){
  font-family: Russian;
}
:lang(en){
  font-family: English;
}
#App {
  max-width: 1980px;
  margin: auto;
  letter-spacing: 2px !important;
  background-color: #ffffff !important;
}
.cursor-pointer{
  cursor: pointer !important;
}
.cursor-default{
  cursor: default !important;
}
.hide{
  display: none !important;
}
/*.h1, h1 {*/
/*  font-size: calc(1.375rem + 2.5vw);*/
/*}*/
/*.h2, h2 {*/
/*  font-size: calc(1.325rem + 1.9vw);*/
/*}*/
/*.h3, h3 {*/
/*  font-size: calc(1.3rem + 1.6vw);*/
/*}*/
/*.h4, h4 {*/
/*  font-size: calc(1.275rem + 1.3vw);*/
/*}*/
/*.h5, h5 {*/
/*  font-size: 2rem;*/
/*}*/
/*.h6, h6 {*/
/*  font-size: 1.5rem;*/
/*}*/
/*p {*/
/*  font-size: 1.5rem;*/
/*}*/
