.slider{
    max-width: 300px;
}
.slider img{
    max-width: 300px;
    padding: 2px;
}
.slick-dots{
    bottom: -50px;
}
.slick-dots img:focus{
    border: none;
}
.slick-dots .slick-active{
    border: 2px rgba(0, 0, 0, 0.3) solid;
    border-radius: 5px;
}
.slick-dots li{
    width: 50px;
    height: 49px;
}
.product-page-container{
    max-width: 1000px;
    min-height: 400px;
}
.product-right-container{
    border: 1px rgba(0, 0, 0, 0.32) solid;
    border-radius: 15px;
    box-shadow: 2px 2px 1px 1px #00000052;
    margin: 10px;
}
@media screen and (max-width: 767px) {
    .product-right-container{
        margin-top: 80px;
    }
}
.delivery-available{
    border: 1px rgba(0, 0, 0, 0.5) solid;
    border-radius: 10px;
    box-shadow: 0 0 1px 1px #00000070;
    padding: 5px;
}
.description-section{
    border: 1px rgba(0, 0, 0, 0.32) solid;
    border-radius: 15px;
    box-shadow: 2px 2px 1px 1px #00000052;
    margin: 70px 10px 10px 10px;
}
.add-to-cart-button{
    margin-bottom: 32.5px !important;
}
.suggested-product-container{
    width: 150px !important;
    font-size: 10px;
}
.suggested-product-container p{
    height: 30px;
    overflow: hidden;
}
.suggested-product-container span{
    color: #6c757d;
}
.slick-arrow::before{
    color: #000;
}
#shopping-basket-flex{
    position: fixed;
    width: 130px;
    height: 83px;
    top: 75px;
    right: -1px;
    background-color: #ffffff;
    border: 1px rgba(0, 0, 0, 0.2) solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: 0;
}
#shopping-basket-flex > span{
    justify-content: left;
    display: grid;
    padding-top: 16.5px;
    padding-left: 10px;
}
#shopping-basket-flex .badge{
    top: 2.5px;
}
.product-page-container .slick-slide{
    border: 1px rgba(0, 0, 0, 0.1) solid;
}
