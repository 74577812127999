.swiper {
    letter-spacing: 2px !important;
}
.header{
    position: relative;
    height: 75px;
    margin: auto;
    background-color: #ffffff;
    text-transform: uppercase;
    border-bottom: 1px rgba(0, 0, 0, 0.13) solid;
}
.menu{
    max-width: 1980px;
    height: 75px;
    padding-left: 35px;
    padding-right: 35px;
}
@media screen and (min-width: 100px) and (max-width: 500px) {
    .menu{
        padding-left: 5px;
        padding-right: 5px;
    }
}
@media screen and (min-width: 1980px) {
    .menu{
        padding-left: 75px;
        padding-right: 75px;
    }
}
@media screen and (min-width: 1400px) and (max-width: 1980px) {
    .menu{
        padding-left: 70px;
        padding-right: 70px;
    }
}
.menu span{
    line-height: 75px;
    text-align: center;
    cursor: pointer;
}
.logo{
    position: absolute;
    height: 45px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    filter: drop-shadow(0 0 8px rgb(37, 0, 253));
}
.header-underline{
    text-decoration: underline;
}
.swiper .MuiPaper-root{
    background-color: rgb(255, 255, 255);
    box-shadow: 75px 0 20px 75px rgba(255, 255, 255, 0.8);
}
.swiper-div{
    width: 280px;
}
.swiper-icon{
    font-size: 2.5rem !important;
}
.sidebar-header{
    position: relative;
    width: 100%;
    height: 75px;
    margin: 0;
    background-color: #ffffff;
    border-bottom: 1px #00000070 solid;
}
.sidebar-header span{
    line-height: 75px;
    text-align: center;
}
.sidebar-header svg{
    filter: drop-shadow( 1px 1px 1px rgb(255, 255, 255));
}
.language-bar img{
    opacity: 0.5;
    border: #989898 1px solid;
    filter: drop-shadow(0 0 1px #989898);
}
.language-bar .select-img img{
    transition: transform 1s;
    opacity: 1;
    border: #131e2b 1px solid;
    filter: drop-shadow(0 0 1px #131e2b);
    transform: scale(1.1);
}
.menu-text a{
    line-height: 75px;
    text-align: center;
    color: #000000;
    cursor: pointer;
}
.menu-text-shadow{
    text-shadow: 0 0 1px rgb(0, 0, 0);
}

.language-menu{
    position: relative;
}
.language-menu span{
    cursor: initial !important;
    filter: drop-shadow(0 0 1px #cbcbcb);
}
.language-dropdown{
    position: absolute;
    top: 15px;
    right: 0;
    left: 0;
    z-index: 1100;

}
.language-dropdown span{
    line-height: 45px;
}
.language-dropdown-wrap{
    width: 42px;
    right: 0;
    left: 0;
    position: absolute;
    margin: auto;
    background-color: #acacac73;
    border-radius: 40px;
}
