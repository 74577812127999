.footer{
    min-height: 100px;
    background-color: #000;
    color: #e1e1e1;
}
.footer-container{
    max-width: 1000px;
    right: 0;
    left: 0;
    margin: auto;
}
.footer-container-first-child{
    margin-top: 20px;
    border-bottom: 2px #ddf3fb solid;
}
.footer-container-first-child a{
    color: #b0b5c1;
}
.footer-logo{
    width: 55px;
    margin: auto;
    display: block;
    filter: drop-shadow(1px 1px 15px rgb(37, 0, 253));
}
